import React from 'react';
import queryString from 'query-string';
import { useState, useEffect } from 'react';
import './App.css';
import ReCAPTCHA from 'react-google-recaptcha';
import { getTransactionStatus, pay } from './common/transactionManager';

// 3 minutes
const MAX_TIME = 180;


export default function Home() {
  let values;
  if (typeof window !== 'undefined') {
    values = queryString.parse(window.location.search);
  } else {
    values = {};
  }

  const [body, setBody] = useState({
    amount: values.amount ? parseFloat(values.amount) : null,
    merchantCode: values.merchant_code || null,
    product: values.product || null,
    next_url: values.next_url || null,
    externalOrderId: values.order_id || null,
    email: values.email || null
  });
  const [tokenReCaptcha, setTokenReCaptcha] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorStatus, setErrorStatus] = useState('');
  const [transaction, setTransaction] = useState('');

  useEffect(() => {
    if (!body.amount || !body.merchantCode || !body.next_url || !body.externalOrderId || !body.product) {
      setErrorMessage('Campi mancanti. Impossibile procedere.');
      setErrorStatus('missing_arguments');
    }
  }, []);

  const isEmailValid = (email) => {
    const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/;
    return emailRegex.test(email);
  };
  const validateBody = (body, tokenReCaptcha) => {
    let errors = '';
    if (!tokenReCaptcha) {
      errors += 'Confermare ReCaptcha per proseguire.\n';
    }
    if (!body.amount) {
      errors += 'Amount necessario per proseguire.\n';
    }
    if (!body.merchantCode) {
      errors += 'Merchant UUID necessario per proseguire.\n';
    }
    if (!body.product) {
      errors += 'Product necessario per proseguire.\n';
    }
    if (!body.next_url) {
      errors += 'Next URL necessario per proseguire.\n';
    }
    if (!body.externalOrderId) {
      errors += 'Order ID necessario per proseguire.\n';
    }
    if (!body.email) {
      errors += 'Inserire email per proseguire.\n';
    }
    if (!isEmailValid(body.email)) {
      errors += 'Email non valida.\n';
    }
    return errors;
  }

  const checkTransaction = (transactionUUID) => {
    let checkStatusRequest = 0;
    let time = 0;
    let checkTransactionStatus;
    const interval = setInterval(async () => {
      if (checkStatusRequest == 0 && time < MAX_TIME) {
        checkTransactionStatus = await getTransactionStatus(transactionUUID, body.merchantCode);
        if (checkTransactionStatus?.data?.statusCode == 200 || checkTransactionStatus?.data?.statusCode == 406) {
          checkStatusRequest = checkTransactionStatus?.data?.statusCode;
          if (checkTransactionStatus?.data?.statusCode == 406) {
            setErrorStatus(checkTransactionStatus?.data?.body);
          }
        }
        time += 3;
      } else {
        clearInterval(interval);
        setIsLoading(false);
        if (checkStatusRequest == 200) {
          window.location.href = `${body.next_url}${body.next_url.includes('?') ? '&' : '?'}transaction_uuid=${transactionUUID}&status_code=200`;
        } else {
          if (checkTransactionStatus?.data?.body != 'denied_request' && checkTransactionStatus?.data?.body != 'confirm_failed') {
            setErrorStatus('expired_transaction');
          }
          setErrorMessage(
            checkTransactionStatus?.data?.body == 'denied_request'
              ? 'Transazione rifiutata'
              : checkTransactionStatus?.data?.body == 'confirm_failed'
                ? 'Transazione fallita'
                : 'Transazione scaduta',
          );
        }
      }
    }, 3000);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const checkErrors = await validateBody(body, tokenReCaptcha);
    if (checkErrors != '') {
      setErrorMessage(checkErrors);
      setIsLoading(false);
      return
    }
    const payment = await pay(body, tokenReCaptcha);
    let errorMessage = '';
    switch (true) {
      case payment?.data?.statusCode != 200 && !payment.data.err:
        errorMessage = 'Errore nella richiesta di pagamento. Riprova più tardi.';
        break;
      case payment?.data?.statusCode != 200 && payment.data.err.errorCode == 'CAPTCHA_NOT_VALID':
        errorMessage = 'Captcha non valido.';
        break;
      case payment?.data?.statusCode != 200 && payment.data.err.errorCode == 'CLIENT_NOT_VALID':
        errorMessage = 'Client non valido.';
        break;
      case payment?.data?.statusCode != 200 && payment.data.err.errorCode == 'ORDER_NOT_VALID':
        errorMessage = 'Ordine non valido.';
        break;
      case payment?.data?.statusCode != 200 && payment.data.err.errorCode == 'MERCHANT_NOT_VALID':
        errorMessage = 'Merchant non valido.';
        break;
      case payment?.data?.statusCode != 200 && payment.data.err.errorCode == 'PAYMENT_NOT_VALID':
        errorMessage = 'Pagamento non valido.';
        break;
      default:
        setTransaction(payment.data.body);
        const transactionUUID = payment.data.body;
        checkTransaction(transactionUUID);
        break;
    }
    if (errorMessage != '') {
      setErrorMessage(errorMessage)
      setIsLoading(false);
    }
  };

  return (
    <main className={`flex min-h-screen flex-col items-center justify-between p-24`}>
      <div>
        {
          (errorMessage == '' || errorMessage != "Campi mancanti. Impossibile procedere.") ?
            <form onSubmit={handleSubmit} className="max-w-sm mx-auto">
              <div className="mb-4 flex justify-center">
                <img src="/logo.png" alt="Logo" className="h-16 w-16 object-contain" />
              </div>
              <div className="mb-4 flex justify-center">
                <label className="block text-gray-700 font-bold mb-2 text-lg" htmlFor="email">
                  {body.amount && body.amount % 1 === 0 ? `${body.amount?.toFixed(2)} €` : `${body.amount} €`}
                </label>
              </div>
              <div className="mb-4 flex justify-center">
                <label className="block text-gray-700 font-light mb-2 text-md text-center leading-tight" htmlFor="email">
                  Per procedere inserisci l’indirizzo email associato al tuo account GoWelfare.
                </label>
              </div>
              <div className="mb-4">
                <div className=" custom-input relative rounded-lg border border-gray-300 h-12">
                  <span className="absolute inset-y-0 left-0 pl-3 flex items-center">
                    <img className="h-5 w-auto text-gray-400" src="/email.png" alt="Email icon" />
                  </span>
                  <input
                    className=" block w-full h-full pl-10 pr-10 py-2 ml-2 rounded-md border-none leading-5 text-gray-bold placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0  sm:text-sm"
                    type="email"
                    placeholder="Inserisci la tua email"
                    onChange={(e) => {
                      setBody({ ...body, email: e.target.value });
                    }}
                    value={body.email || ''}
                    required
                  />
                  <span
                    style={{ cursor: 'ponter', zIndex: 10 }}
                    onClick={() => {
                      if (body.email && body.email != '') {
                        setBody({ ...body, email: '' });
                      }
                    }} className="absolute inset-y-0 right-0 pr-3 flex items-center">
                    <svg
                      style={{ border: '2px solid #9ca3af', borderRadius: '100%', cursor: 'pointer' }}
                      className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" clipRule="evenodd" d="M11.414 10l4.293-4.293a1 1 0 1 0-1.414-1.414L10 8.586 5.707 4.293a1 1 0 1 0-1.414 1.414L8.586 10l-4.293 4.293a1 1 0 1 0 1.414 1.414L10 11.414l4.293 4.293a1 1 0 1 0 1.414-1.414L11.414 10z" />
                    </svg>
                  </span>
                </div>
              </div>
              <div className="flex justify-center">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_KEY || ''}
                  onChange={(token) => { setTokenReCaptcha(token || '') }}
                  className="small-recaptcha"
                />
              </div>
              {errorMessage !== '' &&
                <div className="mb-4 flex justify-center">
                  <label className="block text-gray-700 font-bold mb-2 text-sm text-center leading-tight" style={{ color: '#EB5757' }} htmlFor="email">
                    {errorMessage &&
                      errorMessage.split('\n').map((error, index) => (
                        <p>{error}</p>
                      ))
                    }
                  </label>
                </div>
              }
              <div className="flex justify-center">
                <button className="bg-blue-500 w-full rounded-lg text-sm h-12 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline bg-green-custom" type="submit">
                  Conferma e continua
                </button>
              </div>
              <button
                onClick={() => window.location.href = (transaction && errorStatus) ? `${body.next_url}${body.next_url.includes('?') ? '&' : '?'}transaction_uuid=${transaction}&status_code=500&status=${errorStatus}` : `${body.next_url}/`}
                type='button'
                className=" custom-close-button bg-blue-500 w-full mr-1 rounded-lg text-sm h-12 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline bg-green-custom" >
                Torna al sito
              </button>
              <div className="mb-4 flex justify-center">
                <label className="custom-text block text-gray-700 font-light mb-2 text-sm text-center leading-tight" htmlFor="email">
                  <a href='https://gowelfare.it/' target={'_blank'}>Non hai un account GoWelfare? Registrati</a>
                </label>
              </div>
            </form> :
            <div style={{ width: '40vh' }}>
              <div className="mb-4 flex justify-center">
                <img src="/logo.png" alt="Logo" className="h-16 w-16 object-contain" />
              </div>
              <div className="mb-4 flex justify-center">
                <label className="block text-gray-700 font-bold mb-2 text-md text-center leading-tight" htmlFor="email">
                  {errorMessage}
                </label>
              </div>
              <button
                onClick={() => window.location.href = (transaction && errorStatus) ? `${body.next_url}${body.next_url.includes('?') ? '&' : '?'}transaction_uuid=${transaction}&status_code=500&status=${errorStatus}` : `${body.next_url}/`}
                type='button'
                className=" custom-close-button bg-blue-500 w-full mr-1 rounded-lg text-sm h-12 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline bg-green-custom" >
                Torna al sito
              </button>

            </div>
        }

      </div>
      {
        isLoading && (
          <div className="fixed z-50 inset-0 flex items-center justify-center">
            <div className='custom-loader sm:px-10 px-4'>
              <div>
                <div className="mb-4 flex justify-center">
                  <img src="/logo.png" alt="Logo" className="h-16 w-16 object-contain" />
                </div>
                <label className="block text-gray-700 font-bold mb-2 text-md text-center leading-tight" htmlFor="email">
                  Controlla il tuo smartphone
                </label>
                <p className="block text-gray-700 font-light mb-2 text-md text-center leading-tight" style={{ paddingBottom: '30px' }}>
                  Per proseguire autorizza l’operazione in <span className='font-bold'>APP GoWelfare</span>. Ti basta aprire l’app e confermare il pagamento.
                </p>
                <div className="animate-spin rounded-full h-20 w-20 border-b-4" style={{ borderColor: '#93bd27', margin: '0 auto' }}></div>
              </div>
            </div>
          </div>
        )
      }
    </main >
  );
}
