import axios from 'axios';

export async function getToken() {
    try {
        var url = `${process.env.REACT_APP_AWS_API_GATEWAY}/auth?client_id=${process.env.REACT_APP_CLIENT_ID}&client_secret=${process.env.REACT_APP_CLIENT_SECRET}`;
        let res = await axios({ method: "get", url, headers: { "content-type": "application/json" } });
        if (res.status === 200) return res.data.token;
    } catch (err) { }
    return null;
};

export async function pay(body, tokenReCaptcha) {
    const authToken = await getToken();
    localStorage.setItem("token", authToken);
    const config = {
        headers: {
            Authorization: `${authToken}`
        }
    };
    const requestBody = {
        email: body.email,
        amount: body.amount,
        externalOrderId: body.externalOrderId,
        product: body.product,
        merchantCode: body.merchantCode,
        tokenReCaptcha: tokenReCaptcha,
        fc: 'WidgetPreauthorization',
        requestTimestamp: new Date().getTime(),
        requestRandomValue: Math.floor(Math.random() * 900000) + 100000
    }
    try {
        const response = await axios.post(`${process.env.REACT_APP_AWS_API_GATEWAY}/widget/pay`, requestBody, config);
        return response;
    } catch (error) {
        return error
    }
}

export async function getTransactionStatus(transactionUUID, merchantCode) {
    const authToken = localStorage.getItem("token");
    const config = {
        headers: {
            Authorization: `${authToken}`
        }
    };
    const requestBody = {
        transactionUUID: transactionUUID,
        merchantCode: merchantCode,
        fc: 'GetTransactionStatus',
        requestTimestamp: new Date().getTime(),
        requestRandomValue: Math.floor(Math.random() * 900000) + 100000
    }
    try {
        const response = await axios.post(`${process.env.REACT_APP_AWS_API_GATEWAY}/widget/transaction-status`, requestBody, config);
        return response;
    } catch (error) {
        return error
    }
}